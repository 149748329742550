import React from 'react'
import Navbar from './components/navbar/Navbar'
import Banner from './components/banner/Banner'
import CardsList from './components/list/CardList'
import AboutMe from './components/aboutMe/AboutMe'
import Contact from './components/contact/Contact'

const LandingView = () => {
    return (
        <div>
            <Navbar />
            <Banner />
            <CardsList />
            <AboutMe />
            <Contact />
        </div>
    )
}

export default LandingView