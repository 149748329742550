import { Container, Link, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Contact = () => {
    return (
        <Content id="contact">
            <Container>
                <Typography textAlign={'center'} fontSize={"32px"} fontWeight={'bold'} sx={{ color: 'white' }}>Contacto</Typography>
                <ContactInfo>
                    <List>
                        <Link color='secondary' target="_blank" href={process.env.REACT_APP_LINKEDIN}><LinkedInIcon sx={{ color: 'white' }} /></Link>
                        <Link color='secondary' href={`mailto:${process.env.REACT_APP_EMAIL}?subject=Mail from our Website`}><AlternateEmailIcon sx={{ color: 'white' }} /></Link>
                        <Link color='secondary' target="_blank" href={process.env.REACT_APP_INSTAGRAM}><InstagramIcon sx={{ color: 'white' }} /></Link>
                        <Link color='secondary' target="_blank" href={`https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_WHATSAPP}&text&type=phone_number&app_absent=0`}><WhatsAppIcon sx={{ color: 'white' }} /></Link>
                    </List>
                </ContactInfo>
            </Container>

            <ButtonFloat title="Whatsapp" onClick={() => window.open(`https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_WHATSAPP}&text&type=phone_number&app_absent=0`)}>
                <WhatsAppIcon />
            </ButtonFloat>
        </Content>
    )
}

export default Contact

const Content = styled.div`
    background-color: #004d40;
    padding: 1rem 0;
`

const ContactInfo = styled.div`
    display: flex;
    justify-content: center;
`

const List = styled.div`
    display: flex;
    gap: 12px;
    padding-top: 1rem;
`

const ButtonFloat = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #004d40;
    color: #004d40;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    cursor: pointer;
    transition: all .5s all;
    svg{
        font-size: 2rem !important;
    }
    :hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
`