import { Button, Container, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import styled from 'styled-components';

const Banner = () => {

    const isMobile = useMediaQuery('(max-width:768px)');

    return (
        <Background>
            <ContainerData>
                <Texts>
                    <Typography fontStyle={'italic'} fontSize={isMobile ? '38px' : '36px'} color={'white'} fontWeight={500}>
                        Apasionada por el crecimiento y desarrollo humano
                    </Typography>
                    <br />
                    <Typography fontSize={isMobile ? '18px' : '20px'} color={'white'}>Sesiones de apoyo y crecimiento personal. Relaciones de pareja. Adolecentes. Proposito vocacional y bienestar emocional </Typography>
                    <Buttons>
                        <ButtonFake size='large' variant='outlined' noBorder={true} href={`https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_WHATSAPP}&text=Hola Agus, quiero un turno!&type=phone_number&app_absent=0`}>RESERVA TU TURNO</ButtonFake>
                    </Buttons>
                </Texts>
            </ContainerData>
        </Background>
    )
}

export default Banner

const Background = styled.div`
    height: auto;
    width: 100%;
    background-image: 
        /* linear-gradient(to bottom, transparent 85%, #FFF), Gradiente solo en el último 20% */
        url('/assets/images/image.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #FFF;

    @media (max-width: 768px) {
        width: 100% !important;
        background-size: auto;
        height: auto;
        background-position: left;
        padding-top: 20px;
    }
`;


const ContainerData = styled(Container)`
    display: flex !important;
    align-items: center;
    height: 75vh;
    @media (max-width: 768px) {
        width: 100% !important;
        height: auto;
        flex-direction: column-reverse;
    }
`

const Texts = styled.div`
    width: 50%;
    margin-top: 130px;
    display: flex !important;
    height: fit-content;
    justify-content: flex-start;
    flex-direction: column;
    background-color: rgba(100, 100, 100, 0.1); /* Color blanco con opacidad */
    backdrop-filter: blur(10px); /* Aplicar el efecto de desenfoque */
    -webkit-backdrop-filter: blur(10px); /* Para compatibilidad con Safari */
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 3rem;
    opacity: 0; /* Inicialmente invisible */
    animation: slideInFromLeft 0.8s ease-out forwards; /* Animación */

    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
        height: auto;
    }

    @keyframes slideInFromLeft {
        0% {
            transform: translateX(-100%); /* Comienza fuera de la pantalla */
            opacity: 0;
        }
        100% {
            transform: translateX(0); /* Se desliza hasta su posición original */
            opacity: 1;
        }
    }
`;



const Buttons = styled.div`
    display: flex;
    gap: 12px;
    padding-top: 2rem;
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100% !important;
    }
`

const ButtonFake = styled(Button)`
    border: 2px solid white !important;
    color: white !important;
`

