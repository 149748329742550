import { Link, Container, Button, IconButton, Box, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import styled from 'styled-components';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const isMobile = useMediaQuery('(max-width:768px)');

    const styles = isMobile ? {
        height: 18,
        position: "absolute",
        top: '27px',
        left: '18px'
    } : {
        display: 'none',
    }

    const styles2 = isMobile ? {
        height: 18,
        position: "absolute",
        top: '27px',
        left: '18px'
    } : {
        display: 'flex',
    }

    return (
        <Content id="Home">
            <ContainerCustom>
                <Box
                    component="img"
                    sx={styles}
                    alt={'Agustina Tosolini Psicología'}
                    src={'assets/logo.png'}
                />
                <HamburgerButton onClick={toggleMenu}>
                    {menuOpen ? <CloseIcon color={'secondary'} /> : <MenuIcon color={'secondary'} />}
                </HamburgerButton>
                <List menuOpen={menuOpen}>
                    <Items menuOpen={menuOpen}>
                        <Box
                            component="img"
                            sx={styles2}
                            alt={'Agustina Tosolini Psicología'}
                            src={'assets/images/logo.png'}
                            width={"72px"}
                        />
                        <br />
                        <Link href='#about_me' color={'primary'} underline="none" onClick={() => toggleMenu()}>Sobre mí</Link>
                        <Link href='#offer' color={'primary'} underline="none" onClick={() => toggleMenu()}>¿Qué ofrecemos?</Link>
                        <Link href='#contact' color={'primary'} underline="none" onClick={() => toggleMenu()}>Contacto</Link>
                    </Items>
                    <Buttons menuOpen={menuOpen}>
                        <Button variant='contained' href={`https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_WHATSAPP}&text=Hola Agus, quiero un turno!&type=phone_number&app_absent=0`}>SOLICITAR TURNO</Button>
                    </Buttons>
                </List>
            </ContainerCustom>
        </Content>
    );
};

export default Navbar;

const Content = styled.nav`
    width: 100%;
    height: 90px;
    position: relative;
    z-index: 9999 !important;
    background-color: rgba(247, 252, 248, 0.8); /* Transparente con leve fondo */
    backdrop-filter: blur(10px); /* Efecto de desenfoque */
    -webkit-backdrop-filter: blur(10px); /* Soporte para Safari */
    transition: background-color 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    @media (max-width: 768px) {
        height: 70px;
    }
`;

const ContainerCustom = styled(Container)`
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;
    @media (max-width: 768px) {
        justify-content: flex-end;
        gap: 12px;
    }
`;

const Items = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-size: 18px;
    }
`;

const List = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 74%;
        height: 100%;
        background-color: rgba(11, 17, 25, 0.8); /* Fondo oscuro semitransparente */
        backdrop-filter: blur(15px); /* Efecto de desenfoque en el menú */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        transform: ${props => (props.menuOpen ? 'translateX(0)' : 'translateX(-100%)')};
        transition: transform 0.3s ease-in-out;
        z-index: 1000;
    }
`;

const Buttons = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        width: 100% !important;
        margin-top: 1rem;
        button{
            width: 100%;
        }
    }
`;

const HamburgerButton = styled(IconButton)`
    display: none !important;
    @media (max-width: 768px) {
        display: block !important;
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 1001;
    }
`;
