import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: "#2E7D32", // Un verde más natural, asociado a la naturaleza y el bienestar.
            contrastText: '#F1F8E9', // Un verde pálido para buen contraste.
        },
        secondary: {
            main: "#66BB6A", // Verde más claro, que evoca frescura y salud.
            contrastText: '#FFFFFF',
        },
        info: {
            main: "#388E3C", // Un tono profundo de verde para información importante.
            contrastText: '#E8F5E9', // Verde claro que da tranquilidad visual.
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    textTransform: 'none',
                    borderRadius: '8px',
                    border: '1.5px solid',
                    borderColor: '#66BB6A', // Color verde claro para botones.
                    boxShadow: 'none',
                    fontWeight: 'bold',
                    '&:hover': {
                        backgroundColor: '#81C784', // Sutil verde al pasar el ratón.
                    },
                },
            },
        },
    },
});
