import { Container, Link, Typography } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const AboutMe = () => {
    return (
        <Container id="about_me">
            <Typography textAlign={'center'} fontSize={"32px"} fontWeight={'bold'} color='primary'>Sobre mí</Typography>
            <About>
                <Profile>
                    <Img src='/assets/images/agus.jpg' alt="Licenciada en Psicología y Docente"/>
                </Profile>
                <Information>
                    <Typography>¡Hola! Soy <Link href={process.env.REACT_APP_LINKEDIN}>Agustina Tosolini</Link>, Licenciada en Psicología y docente. Mi pasión es acompañar a las personas en su camino de crecimiento personal y bienestar. Me encanta trabajar contigo para que descubras esa versión de ti mismo/a que te haga sentir en equilibrio, avanzando hacia tus metas y viviendo con más satisfacción.</Typography>
                    <Typography>Además, me encanta leer y practicar yoga aéreo, dos actividades que considero clave para mantener mi propio bienestar. Para mí, el crecimiento personal no tiene que ser complicado; a veces, lo simple es lo más poderoso. Estoy aquí para caminar a tu lado y ayudarte a encontrar ese balance en tu vida.</Typography>
                    <Typography fontStyle={'italic'}>"Las cosas no nos influyen por lo que son sino por lo que creemos que son".</Typography>
                    <Typography fontWeight={600}>MAT. 5585</Typography>
                </Information>
            </About>
        </Container>
    )
}

export default AboutMe

const About = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 2rem 0;
`

const Information = styled.div`
    display: flex;
    width: 400px;
    height: 405px;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
`

const Img = styled.div`
    background-image: url(${p => p.src});
    background-position: center;
    background-size: cover;
    width: 400px;
    height: 405px;
    border: 2px solid #2e7d32;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const Profile = styled.div`
    display: flex;
    justify-content: center;
`