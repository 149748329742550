import React from 'react';
import { Typography, Container } from '@mui/material';
import styled from 'styled-components';

// Datos de ejemplo de las tarjetas
const cardsData = [
    {
        title: "Relaciones de Pareja",
        description: "Las parejas atraviesan muchas y diferentes etapas, entre ellas el conflicto. Si ambas personas desean reconfigurar el vínculo es posible.",
        image: "/assets/images/pareja.jpg",
    },
    {
        title: "Adolescentes",
        description: "En un mundo cambiante e instantáneo los adolescentes pueden estar olvidando tener momentos de presencia y autovaloración cruciales para enfrentar desafíos de hoy y del futuro.",
        image: "/assets/images/adolecentes.jpg",
    },
    {
        title: "Propósito vocacional",
        description: "Es un mito que debemos saber quienes queremos ser o hacer a cierta edad. Este propósito puede variar, e incluso aparecer en diferentes etapas de nuestra vida.",
        image: "/assets/images/proposito.jpg",
    },
    {
        title: "Bienestar emocional",
        description: "No hay emociones buenas o malas, todas son necesarias para que puedas adaptarte al mundo que transitas. Si estas están afectando tu bienestar sería bueno comprender que lugar y tiempo les estamos brindando.",
        image: "/assets/images/bienestar.jpg",
    },
    {
        title: "Crecimiento Profesional",
        description: "Hay otras formas por las cuales asistir a terapia, los problemas son parte de nuestra vida, no buscamos que desaparezcan, buscaremos formas de solucionarlos activamente y fortalecer el autovalor para ello.",
        image: "/assets/images/cre_profesional.jpg",
    },
    {
        title: "Autoestima",
        description: "Hacer crecer el amor propio es un viaje complejo y profundo sin embargo muy posible, e implica muchas mas areas de las que imaginamos.",
        image: "/assets/images/autoestima.jpg",
    },
];

const CardsList = () => {
    return (
        <Content id="offer">
            <Container>
                <Typography textAlign={'center'} fontSize={"32px"} fontWeight={'bold'} color='primary'>¿Qué ofrecemos?</Typography>
                <br />
                {cardsData.map((card, index) => (
                    <CardItem key={index}>
                        <ImageContent>
                            <Image src={card.image} alt={card.title}/>
                        </ImageContent>
                        <ContentCard>
                            <Typography variant="h6" component="div" color="primary">
                                {card.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {card.description}
                            </Typography>
                        </ContentCard>
                    </CardItem>
                ))}
            </Container>
        </Content>
    );
};

export default CardsList;

const Content = styled.div`
    /* position: relative; */
    top: -5px;
    z-index: 999 !important;
    padding: 5rem 0;
`

// Estilos personalizados para la Card
const CardItem = styled.div`
    display: flex;
    margin-bottom: 16px; /* Espacio entre cada Card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    border-radius: 12px;
    height: 130px;
    overflow: hidden;


    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
    }

    .MuiCardContent-root {
        background-color: rgba(255, 255, 255, 0.8); /* Fondo blanco semitransparente */
        backdrop-filter: blur(5px); /* Efecto blur */
    }

    .MuiTypography-h6 {
        font-weight: bold;
        color: #004d40; /* Color verde oscuro para representar la psicología */
    }

    .MuiTypography-body2 {
        color: #616161;
    }
`;

const Image = styled.img`
    width: 80px;
    min-width: 80px;
    height: 80px;
    min-height: 80px;
    border-radius: 50%;
    border: 1px solid #004d40;
`

const ImageContent = styled.div`
    width: 100px;
    min-width: 100px;
    height: 100%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ContentCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
`