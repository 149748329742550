import { ThemeProvider } from '@mui/material';
import './App.css';
import Landing from './pages/landing/Landing';
import { theme } from './theme/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Landing />
    </ThemeProvider>
  );
}

export default App;